<latch-loading [isActive]="isLoading" class="latch-page">
  <div class="card" *ngIf="!isLoading && !!reservation">
    <div class="content">
      <div *ngIf="hasRemainingKeycards">
        <markdown
          *ngIf="!hasExistingKeycards"
          class="no-existing-keycards"
          [data]="getMarkdown(KeycardOnboardingInfo.NO_EXISTING_KEYCARDS)"
        ></markdown>
        <markdown
          *ngIf="hasExistingKeycards"
          class="has-existing-keycards"
          [data]="getMarkdown(KeycardOnboardingInfo.HAS_EXISTING_KEYCARDS)"
        ></markdown>
      </div>
      <markdown
        *ngIf="!hasRemainingKeycards"
        class="no-remaining-keycards"
        [data]="getMarkdown(KeycardOnboardingInfo.NO_REMAINING_KEYCARDS)"
      ></markdown>
    </div>
    <div class="footer">
      <div *ngIf="hasRemainingKeycards" class="button-wrapper">
        <a href="{{ token }}/keycards/qr-code" class="latch-button latch-dark">
          Start Scanning
        </a>
      </div>
      <div class="latch-powered">
        <img class="latch-powered-logo-light" src="../../../../assets/images/works-with-latch-light.svg" />
        <img class="latch-powered-logo-dark" src="../../../../assets/images/works-with-latch-dark.svg" />
      </div>
    </div>
  </div>
</latch-loading>
