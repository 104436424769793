export enum AdmittanceInfo {
  HEADER = 'ADMITTANCE_INFORMATION.HEADER',
  PROPERTY_INFORMATION = 'ADMITTANCE_INFORMATION.PROPERTY_INFORMATION',

  DOORCODE_ACCESS_INFORMATION = 'ADMITTANCE_INFORMATION.ACCESS_INFORMATION.DOORCODE_ACCESS_INFORMATION',
  KEYCARD_ACCESS_INFORMATION = 'ADMITTANCE_INFORMATION.ACCESS_INFORMATION.KEYCARD_ACCESS_INFORMATION',
  APP_ACCESS_INFORMATION = 'ADMITTANCE_INFORMATION.ACCESS_INFORMATION.APP_ACCESS_INFORMATION',

  TEMPORARY_DOORCODE_SECURITY_MESSAGE = 'ADMITTANCE_INFORMATION.DOORCODE_DISPLAY.TEMPORARY_DOORCODE_SECURITY_MESSAGE',
  DOORCODES_UNAVAILABLE_MESSAGE = 'ADMITTANCE_INFORMATION.DOORCODE_DISPLAY.DOORCODES_UNAVAILABLE_MESSAGE',
  EXPIRED_ACCESS_INFORMATION = 'ADMITTANCE_INFORMATION.EXPIRED_ACCESS_INFORMATION'
}

export enum KeycardOnboardingInfo {
  HAS_EXISTING_KEYCARDS = 'KEYCARD_ONBOARDING_INFORMATION.HAS_EXISTING_KEYCARDS',
  NO_EXISTING_KEYCARDS = 'KEYCARD_ONBOARDING_INFORMATION.NO_EXISTING_KEYCARDS',
  NO_REMAINING_KEYCARDS = 'KEYCARD_ONBOARDING_INFORMATION.NO_REMAINING_KEYCARDS'
}

export enum KeycardScanning {
  SCANNING_INSTRUCTIONS = 'KEYCARD_SCANNING.SCANNING_INSTRUCTIONS'
}

export enum KeycardManualEntry {
  MANUAL_ENTRY_INSTRUCTIONS = 'KEYCARD_MANUAL_ENTRY.MANUAL_ENTRY_INSTRUCTIONS'
}

export enum KeycardConfirmation {
  KEYCARD_ACTIVATED_CONFIRMATION = 'KEYCARD_CONFIRMATION_PAGE.KEYCARD_ACTIVATED_CONFIRMATION',
  REMAINING_KEYCARDS_INSTRUCTIONS = 'KEYCARD_CONFIRMATION_PAGE.REMAINING_KEYCARDS_INSTRUCTIONS',
  NO_REMAINING_KEYCARDS_INSTRUCTIONS = 'KEYCARD_CONFIRMATION_PAGE.NO_REMAINING_KEYCARDS_INSTRUCTIONS'
}

export type MarkdownSectionKey =
  | AdmittanceInfo
  | KeycardOnboardingInfo
  | KeycardScanning
  | KeycardManualEntry
  | KeycardConfirmation;

export enum ContentStringKey {
  PRIVATE_DOOR_PLACEHOLDER = 'ADMITTANCE_INFORMATION.DOORCODE_DISPLAY.PRIVATE_DOOR_PLACEHOLDER'
}

export enum TemplateVariable {
  ALLOWED_KEYCARD_COUNT = 'ALLOWED_KEYCARD_COUNT',
  AVAILABLE_KEYCARD_COUNT = 'AVAILABLE_KEYCARD_COUNT',
  ACTIVATED_KEYCARD_SERIAL_NUMBER = 'ACTIVATED_KEYCARD_SERIAL_NUMBER',
  ACTIVATED_KEYCARD_SERIAL_NUMBER_LAST_FOUR_DIGITS = 'ACTIVATED_KEYCARD_SERIAL_NUMBER_LAST_FOUR_DIGITS'
}

export namespace TemplateVariable {
  export function keys(): TemplateVariable[] {
    return [
      TemplateVariable.ALLOWED_KEYCARD_COUNT,
      TemplateVariable.AVAILABLE_KEYCARD_COUNT,
      TemplateVariable.ACTIVATED_KEYCARD_SERIAL_NUMBER,
      TemplateVariable.ACTIVATED_KEYCARD_SERIAL_NUMBER_LAST_FOUR_DIGITS
    ];
  }
}
