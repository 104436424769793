<latch-loading [isActive]="isLoading" class="latch-page">
  <div class="card" *ngIf="!isLoading">
    <div class="page-header">Latch</div>
    <div *ngIf="admittanceExpired" class="content">
      <markdown
        class="expired-access-information"
        [data]="getMarkdown(AdmittanceInfo.EXPIRED_ACCESS_INFORMATION)"
      ></markdown>
    </div>
    <div *ngIf="!!admittance && !admittanceExpired" class="content">
      <markdown class="header" [data]="getMarkdown(AdmittanceInfo.HEADER)"></markdown>

      <!-- Admittance Information -->
      <latch-admittance-time class="admittance-time" [admittance]="admittance"></latch-admittance-time>
      <!-- Property and Access information -->
      <markdown class="property-information" [data]="getMarkdown(AdmittanceInfo.PROPERTY_INFORMATION)"></markdown>
      <markdown
        *ngIf="isDoorcodeEnabled"
        class="doorcode-access-information"
        [data]="getMarkdown(AdmittanceInfo.DOORCODE_ACCESS_INFORMATION)"
      ></markdown>
      <markdown
        *ngIf="canUseKeycards"
        class="keycard-access-information"
        [data]="getMarkdown(AdmittanceInfo.KEYCARD_ACCESS_INFORMATION)"
      ></markdown>
      <markdown class="app-access-information" [data]="getMarkdown(AdmittanceInfo.APP_ACCESS_INFORMATION)"></markdown>
      <!-- Doorcode Information -->
      <div *ngIf="isDoorcodeEnabled" class="doorcode-wrapper">
        <div *ngIf="canUseDoorcodes && doorsAndCodesOrdered.length > 0">
          <markdown
            *ngIf="hasTemporaryDoorcode"
            class="temporary-doorcode-security-message"
            [data]="getMarkdown(AdmittanceInfo.TEMPORARY_DOORCODE_SECURITY_MESSAGE)"
          ></markdown>
          <latch-doorcode-display
            class="doorcode-display"
            [doorsAndCodes]="doorsAndCodesOrdered"
            [doorcodeStartTime]="doorcodeStartTime"
            [doorcodeEndTime]="doorcodeEndTime"
            [hasTemporaryDoorcode]="hasTemporaryDoorcode"
            [privateDoorPlaceholderName]="privateDoorPlaceholder"
          ></latch-doorcode-display>
        </div>
        <markdown
          *ngIf="!canUseDoorcodes"
          class="doorcodes-unavailable"
          [data]="getMarkdown(AdmittanceInfo.DOORCODES_UNAVAILABLE_MESSAGE)"
        ></markdown>
      </div>
    </div>
    <div class="footer">
      <div *ngIf="!!admittance && !admittanceExpired" class="alternative-access-links">
        <div class="button-wrapper app-access-button">
          <a href="{{ token }}/app" class="latch-button-outline latch-dark">
            Register for the Latch app
          </a>
        </div>
        <div *ngIf="canUseKeycards" class="button-wrapper keycard-access-button">
          <a href="{{ token }}/keycards" class="latch-button-outline latch-dark">
            Activate Keycards
          </a>
        </div>
      </div>
      <div class="latch-powered">
        <img class="latch-powered-logo-light" src="../../../assets/images/works-with-latch-light.svg" />
        <img class="latch-powered-logo-dark" src="../../../assets/images/works-with-latch-dark.svg" />
      </div>
    </div>
  </div>
</latch-loading>
