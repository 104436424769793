import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Style } from 'app/models/style';
import { AdmittanceService, AdmittanceServiceErrors } from 'app/services/admittance/admittance.service';
import { ContentService } from 'app/services/content/content.service';
import { LinkService } from 'app/services/link/link.service';
import { Subscription } from 'rxjs';
import { concatMap, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';

import { CssLoader } from '../shared/css-util/css-loader';
import { MarkdownSectionKey } from '../template/content-keys';
import { ContentProvider } from '../template/content-provider';

export abstract class KeycardBaseComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  token: string;
  protected style: Style;
  protected contentProvider: ContentProvider;
  protected subscription: Subscription;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected admittanceService: AdmittanceService,
    protected contentService: ContentService,
    protected linkService: LinkService,
    protected router: Router,
    protected document: Document
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.handlePageLoad();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getMarkdown(markdownSectionKey: MarkdownSectionKey): string | null {
    return this.contentProvider.getFormattedMarkdown(markdownSectionKey);
  }

  protected handlePageLoad(): void {
    const token$ = this.activatedRoute.params.pipe(
      map((params: Params) => params.token),
      distinctUntilChanged(),
      take(1)
    );

    this.subscription = token$
      .pipe(
        switchMap(token => {
          this.token = token;
          return this.linkService.getStyle(token);
        }),
        concatMap(style => {
          this.style = style;
          CssLoader.loadStyle(this.style, this.document);
          return this.contentService.getContent(this.style.id);
        }),
        concatMap(content => {
          this.contentProvider = ContentProvider.getInstance(this.style, content);
          return this.admittanceService.getAdmittance(this.token);
        })
      )
      .subscribe(
        _ => {
          this.isLoading = false;
        },
        error => {
          console.error(error);
          if (
            error.message === AdmittanceServiceErrors.ADMITTANCE_EXPIRED ||
            error.message === AdmittanceServiceErrors.ADMITTANCE_CANCELLED
          ) {
            this.router.navigate(['/', this.token]);
          }
          if (this.style === undefined) {
            CssLoader.loadDefaultStyle(this.document);
          }
          this.isLoading = false;
        }
      );
  }

  /**
   * Validates key card serial number and returns null if validation succeeded or otherwise an error message.
   */
  protected validateSerialNumber(serialNumber: string | null): string | null {
    if (!serialNumber) {
      return 'Serial number is required.';
    }
    if (serialNumber.length !== 12) {
      return 'Serial number must be exactly 12 digits.';
    }
    const numericOnlyRegex = new RegExp('^[0-9]+$');
    if (!numericOnlyRegex.test(serialNumber)) {
      return 'Serial number can only contain numbers.';
    }
    return null;
  }
}
