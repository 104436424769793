import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DoorAndCode } from 'app/models/doorAndCode';
import { Reservation } from 'app/models/reservation';
import { Style } from 'app/models/style';
import { ContentService } from 'app/services/content/content.service';
import { LinkService } from 'app/services/link/link.service';
import * as _ from 'lodash';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';

import { CssLoader } from '../shared/css-util/css-loader';
import { AdmittanceInfo, ContentStringKey, MarkdownSectionKey, TemplateVariable } from '../template/content-keys';
import { ContentProvider } from '../template/content-provider';

import { Admittance, AdmittanceType, Door, LockAccessibility } from './../../models/admittance';
import { AdmittanceDoorcode, PasscodeType } from './../../models/admittanceDoorcode';
import { AdmittanceService, AdmittanceServiceErrors } from './../../services/admittance/admittance.service';

const COMMON_DOOR: Door = {
  id: '',
  name: 'Building Code',
  accessibility: LockAccessibility.Public
};

@Component({
  selector: 'latch-admittance-info',
  templateUrl: './admittance-info.component.html'
})
export class AdmittanceInfoComponent implements OnInit {
  token: string;
  style: Style;
  admittance: Admittance | null = null;
  doorsAndCodesOrdered: DoorAndCode[];
  isLoading: boolean;
  hasTemporaryDoorcode: boolean;
  doorcodeStartTime: Date;
  doorcodeEndTime: Date;
  admittanceExpired: boolean;
  AdmittanceInfo = AdmittanceInfo;
  private allDoorsHaveDoorcodes: boolean;
  private contentProvider: ContentProvider;
  private reservation: Reservation | null = null;
  private doorGroupOrder: LockAccessibility[] = [
    LockAccessibility.Private,
    LockAccessibility.Public,
    LockAccessibility.Communal,
    LockAccessibility.Service
  ];
  private doorGroupSort = [
    doorAndCode => this.doorGroupOrder.indexOf(doorAndCode.door.accessibility),
    doorAndCode => doorAndCode.door.name
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private admittanceService: AdmittanceService,
    private contentService: ContentService,
    private linkService: LinkService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.hasTemporaryDoorcode = false;
    this.allDoorsHaveDoorcodes = true;
    this.admittanceExpired = false;
    this.handlePageLoad();
  }

  handlePageLoad() {
    const token$ = this.activatedRoute.params.pipe(
      map((params: Params) => params.token),
      distinctUntilChanged(),
      take(1)
    );

    this.doorsAndCodesOrdered = [];

    token$
      .pipe(
        switchMap(token => {
          this.token = token;
          return this.linkService.getStyle(token);
        }),
        concatMap(style => {
          this.style = style;
          return this.contentService.getContent(this.style.id);
        }),
        concatMap(content => {
          this.contentProvider = ContentProvider.getInstance(this.style, content);
          CssLoader.loadStyle(this.style, this.document);
          return this.admittanceService.getAdmittance(this.token);
        }),
        concatMap(admittance => {
          this.admittance = admittance;
          return this.getReservationForAdmittance(this.admittance, this.token);
        }),
        concatMap(reservation => {
          this.reservation = reservation;
          this.contentProvider.setTemplateVariable(
            TemplateVariable.AVAILABLE_KEYCARD_COUNT,
            String(reservation.getAvailableKeycardCount())
          );
          this.contentProvider.setTemplateVariable(
            TemplateVariable.ALLOWED_KEYCARD_COUNT,
            String(reservation.allowedKeycardCount)
          );
          if (!this.admittance) {
            return throwError('Admittance is null');
          }
          return this.getDoorcodesForReservation(this.reservation, this.admittance, this.token);
        })
      )
      .subscribe(
        admittanceDoorcodes => {
          this.doorsAndCodesOrdered = this.getDoorAndCodesFromAdmittanceDoorcodes(admittanceDoorcodes);
          this.admittanceExpired = false;
          this.isLoading = false;
        },
        error => {
          this.admittance = null;
          this.reservation = null;
          this.doorsAndCodesOrdered = [];
          this.admittanceExpired =
            error.message === AdmittanceServiceErrors.ADMITTANCE_EXPIRED ||
            error.message === AdmittanceServiceErrors.ADMITTANCE_CANCELLED;
          console.error(error);
          if (this.style === undefined) {
            CssLoader.loadDefaultStyle(this.document);
          }
          this.isLoading = false;
        }
      );
  }

  get canUseDoorcodes(): boolean {
    if (!this.reservation || !this.admittance) {
      return false;
    }
    return (
      this.reservation.isDoorcodeEnabled &&
      (this.admittance.isActive() || (!this.admittance.isExpired() && this.allDoorsHaveDoorcodes))
    );
  }

  get isDoorcodeEnabled(): boolean {
    if (!this.reservation) {
      return false;
    }
    return this.reservation.isDoorcodeEnabled;
  }

  get canUseKeycards(): boolean {
    if (!this.admittance || !this.reservation) {
      return false;
    }
    return (
      this.reservation.getAvailableKeycardCount() > 0 && !this.admittance.isExpired() && this.admittance.isConnected
    );
  }

  get privateDoorPlaceholder(): string | null {
    if (this.hasPrivateDoor()) {
      return null;
    }
    try {
      return this.contentProvider.getContentString(ContentStringKey.PRIVATE_DOOR_PLACEHOLDER);
    } catch (e) {
      // normally the private door placeholder will not be set
      return null;
    }
  }

  getMarkdown(markdownSectionKey: MarkdownSectionKey): string | null {
    return this.contentProvider.getFormattedMarkdown(markdownSectionKey);
  }

  hasPrivateDoor(): boolean {
    return !!this.admittance && this.admittance.doorDetails.some(d => d.accessibility === LockAccessibility.Private);
  }

  shouldShowDoorcodes(admittance: Admittance): boolean {
    return admittance.isActive() || (!admittance.isExpired() && this.allDoorsHaveDoorcodes);
  }

  /**
   * Retrieves available doorcodes for the given admittance, or skips retrieval if the admittance is
   * expired.
   */
  private getDoorcodesForReservation(
    reservation: Reservation,
    admittance: Admittance,
    token: string
  ): Observable<AdmittanceDoorcode[]> {
    if (reservation.isDoorcodeEnabled && !admittance.isExpired()) {
      return this.admittanceService.getAdmittanceDoorcodes(token);
    } else {
      // The reservation has doorcodes disabled or the admittance is expired; don't even attempt to get doorcodes.
      const doorcodes: AdmittanceDoorcode[] = [];
      return of(doorcodes);
    }
  }

  private getReservationForAdmittance(admittance: Admittance, token: string): Observable<Reservation> {
    if (admittance.type === AdmittanceType.Reservation) {
      return this.admittanceService.getReservation(token);
    } else {
      return throwError('Admittance is not a Reservation');
    }
  }

  private getDoorAndCodesFromAdmittanceDoorcodes(admittanceDoorcodes: AdmittanceDoorcode[]): DoorAndCode[] {
    // Actually does a lot of extra things - sorts by accessibility, sets the doorcode starTime and endTime,
    // sets the hasTemporaryDoorcodeFlag, and sets allDoorsHaveDoorcodes flag
    const doorsAndCodesOrdered: DoorAndCode[] = [];
    if (!this.admittance) {
      return doorsAndCodesOrdered;
    }
    for (const door of this.admittance.doorDetails) {
      const doorcode: AdmittanceDoorcode | undefined = _.find(
        admittanceDoorcodes,
        (d: AdmittanceDoorcode) => d.lockId.toLowerCase() === door.id.toLowerCase()
      );
      if (doorcode && doorcode.passcodeType !== PasscodeType.PERMANENT && !this.hasTemporaryDoorcode) {
        this.hasTemporaryDoorcode = true;
        this.doorcodeStartTime = doorcode.startTime;
        this.doorcodeEndTime = doorcode.endTime;
      }
      const doorAndCode: DoorAndCode = { door: door };
      if (doorcode) {
        doorAndCode.doorcode = doorcode.doorcode;
      } else {
        this.allDoorsHaveDoorcodes = false;
      }
      doorsAndCodesOrdered.push(doorAndCode);
    }

    return _.sortBy(this.getCollapsedDoorAndCodes(doorsAndCodesOrdered), this.doorGroupSort);
  }

  /**
   * If doorAndCodes contains a set of non-private doors that share a common doorcode, returns a list
   * where those doors have all been replaced by a single 'Common Doors' entry.
   *
   * If there are multiple sets with the same doorcode, replaces only the largest set.
   *
   * If the largest group has size 1 (there are no non-private doors with the same doorcode), the list is
   * returned unmodified.
   */
  private getCollapsedDoorAndCodes(doorAndCodes: DoorAndCode[]): DoorAndCode[] {
    // Find the largest group of public doors with the same doorcode.
    const largestPublicDoorGroup: DoorAndCode[] = this.getLargestCommonDoorcodeGroup(doorAndCodes);
    // If the group has more than one door, replace the individual doors with a single 'Common Doors' entry.
    if (largestPublicDoorGroup.length > 1) {
      // Build the "common door" entry that will replace all the individual doors.
      const commonDoorAndCode: DoorAndCode = {
        door: COMMON_DOOR,
        doorcode: largestPublicDoorGroup[0].doorcode
      };

      // Remove any doors that were in the group of doors with the common doorcode.
      const collapsed = doorAndCodes.filter(
        (doorAndCode: DoorAndCode) => !_.find(largestPublicDoorGroup, { door: { id: doorAndCode.door.id } })
      );
      // Add the "common door" entry.
      collapsed.push(commonDoorAndCode);

      return collapsed;
    }

    return doorAndCodes;
  }

  /**
   * Find the largest group of non-private doors which all have the same doorcode.
   *
   * @returns A list containing all the doors which share this common doorcode (along with their code).
   * If doorAndCodes has no non-private doors, returns an empty list.
   */
  private getLargestCommonDoorcodeGroup(doorAndCodes: DoorAndCode[]): DoorAndCode[] {
    // Filter down to just non-private doors.
    const publicDoorAndCodes: DoorAndCode[] = _.filter(
      doorAndCodes,
      (doorAndCode: DoorAndCode) => doorAndCode.door.accessibility !== LockAccessibility.Private
    );
    // groupBy will give us a map from doorcode to the list of DoorAndCodes with that doorcode.
    // values will then give us just the lists themselves - so each item in publicDoorsWithCodeGroups
    // is itself a list of all doors that share a code.
    const publicDoorsWithCodeGroups: DoorAndCode[][] = _.values(
      _.groupBy(publicDoorAndCodes, (doorAndCode: DoorAndCode) => doorAndCode.doorcode)
    );
    // Are there any groups at all? (would be empty if there were no non-private doors).
    if (publicDoorsWithCodeGroups.length > 0) {
      // Sort and take the largest group (the one with the most doors).
      return _.sortBy(
        publicDoorsWithCodeGroups,
        (publicDoorsWithCode: DoorAndCode[]) => publicDoorsWithCode.length
      ).reverse()[0];
    }

    return [];
  }
}
