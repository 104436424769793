import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BarcodeFormat } from '@zxing/library';
import { KeycardScanning } from 'app/components/template/content-keys';
import {
  AdmittanceService,
  AdmittanceServiceErrorMessages,
  AdmittanceServiceErrors
} from 'app/services/admittance/admittance.service';
import { ContentService } from 'app/services/content/content.service';
import { LinkService } from 'app/services/link/link.service';

import { KeycardBaseComponent } from '../keycard-input-base.component';

@Component({
  selector: 'latch-keycard-qr-scanning',
  templateUrl: './keycard-qr-scanning.component.html'
})
export class KeycardQRScanningComponent extends KeycardBaseComponent {
  allowedFormats = [BarcodeFormat.QR_CODE];
  errorMessage: string | null = null;
  KeycardScanning = KeycardScanning;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected admittanceService: AdmittanceService,
    protected contentService: ContentService,
    protected linkService: LinkService,
    protected router: Router,
    @Inject(DOCUMENT) protected document: Document
  ) {
    super(activatedRoute, admittanceService, contentService, linkService, router, document);
  }

  handleNoCameras(): void {
    this.errorMessage = 'No cameras found';
  }

  handleScanError(error: any): void {
    this.errorMessage = 'An error has occured while scanning for qr codes.';
  }

  handleScanSuccess(keycardSerialNumber: string): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    const validationResult: string | null = this.validateSerialNumber(keycardSerialNumber);
    if (validationResult) {
      this.errorMessage = validationResult;
      this.isLoading = false;
      return;
    }
    this.errorMessage = null;
    this.admittanceService.postReservationKeycard(this.token, keycardSerialNumber).subscribe(
      () => {
        this.router.navigate(['/', this.token, 'keycards', keycardSerialNumber, 'confirmation'], {
          queryParamsHandling: 'preserve'
        });
      },
      error => {
        console.error(error);
        const admittanceError =
          AdmittanceServiceErrors.INVALID_KEYCARD_SERIAL_NUMBER === error.message
            ? AdmittanceServiceErrors.INVALID_KEYCARD_SERIAL_NUMBER
            : AdmittanceServiceErrors.INTERNAL_SERVER_ERROR;
        const admittanceErrorMessage = AdmittanceServiceErrorMessages.get(admittanceError);
        if (admittanceErrorMessage) {
          this.errorMessage = admittanceErrorMessage;
        } else {
          throw new TypeError(`Error message not defined for ${admittanceError}`);
        }
        this.isLoading = false;
      }
    );
  }
}
