import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CssLoader } from 'app/components/shared/css-util/css-loader';
import { KeycardConfirmation, MarkdownSectionKey, TemplateVariable } from 'app/components/template/content-keys';
import { ContentProvider } from 'app/components/template/content-provider';
import { Admittance } from 'app/models/admittance';
import { Reservation } from 'app/models/reservation';
import { Style } from 'app/models/style';
import { AdmittanceService, AdmittanceServiceErrors } from 'app/services/admittance/admittance.service';
import { ContentService } from 'app/services/content/content.service';
import { LinkService } from 'app/services/link/link.service';
import { forkJoin, throwError } from 'rxjs';
import { concatMap, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'latch-keycard-confirmation',
  templateUrl: './keycard-confirmation.component.html'
})
export class KeycardConfirmationComponent implements OnInit {
  isLoading: boolean;
  token: string;
  admittance: Admittance;
  reservation: Reservation | null = null;

  KeycardConfirmation = KeycardConfirmation;

  private contentProvider: ContentProvider;
  private serialNumber: string;
  private style: Style;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private admittanceService: AdmittanceService,
    private contentService: ContentService,
    private linkService: LinkService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.handlePageLoad();
  }

  get hasRemainingKeycards(): boolean {
    return !!this.reservation && this.reservation.getAvailableKeycardCount() > 0;
  }

  get hasMultipleRemainingKeycards(): boolean {
    return !!this.reservation && this.reservation.getAvailableKeycardCount() > 1;
  }

  get hasOneRemainingKeycard(): boolean {
    return !!this.reservation && this.reservation.getAvailableKeycardCount() === 1;
  }

  get hasNoRemainingKeycards(): boolean {
    return !!this.reservation && this.reservation.getAvailableKeycardCount() === 0;
  }

  getMarkdown(markdownSectionKey: MarkdownSectionKey): string | null {
    return this.contentProvider.getFormattedMarkdown(markdownSectionKey);
  }

  private handlePageLoad(): void {
    const token$ = this.activatedRoute.params.pipe(
      map((params: Params) => params.token),
      distinctUntilChanged(),
      take(1)
    );

    const serialNumber$ = this.activatedRoute.params.pipe(
      map((params: Params) => params.serialNumber),
      distinctUntilChanged(),
      take(1)
    );

    forkJoin(token$, serialNumber$)
      .pipe(
        switchMap(([token, serialNumber]) => {
          this.token = token;
          if (!serialNumber || serialNumber.length < 4) {
            throwError('invalid serial number');
          }
          this.serialNumber = serialNumber;
          return this.linkService.getStyle(token);
        }),
        concatMap(style => {
          this.style = style;
          CssLoader.loadStyle(this.style, this.document);
          return this.contentService.getContent(this.style.id);
        }),
        concatMap(content => {
          this.contentProvider = ContentProvider.getInstance(this.style, content);
          return this.admittanceService.getAdmittance(this.token);
        }),
        concatMap(admittance => {
          this.admittance = admittance;
          return this.admittanceService.getReservation(this.token);
        })
      )
      .subscribe(
        reservation => {
          this.reservation = reservation;
          this.setTemplateVariables();
          this.isLoading = false;
        },
        error => {
          console.error(error);
          this.reservation = null;
          if (
            error.message === AdmittanceServiceErrors.ADMITTANCE_EXPIRED ||
            error.message === AdmittanceServiceErrors.ADMITTANCE_CANCELLED
          ) {
            this.router.navigate(['/', this.token]);
          }
          if (this.style === undefined) {
            CssLoader.loadDefaultStyle(this.document);
          }
          this.isLoading = false;
        }
      );
  }

  private setTemplateVariables(): void {
    this.contentProvider.setTemplateVariable(TemplateVariable.ACTIVATED_KEYCARD_SERIAL_NUMBER, this.serialNumber);
    this.contentProvider.setTemplateVariable(
      TemplateVariable.ACTIVATED_KEYCARD_SERIAL_NUMBER_LAST_FOUR_DIGITS,
      this.serialNumber.slice(-4)
    );
    if (this.reservation) {
      this.contentProvider.setTemplateVariable(
        TemplateVariable.AVAILABLE_KEYCARD_COUNT,
        String(this.reservation.getAvailableKeycardCount())
      );
      this.contentProvider.setTemplateVariable(
        TemplateVariable.ALLOWED_KEYCARD_COUNT,
        String(this.reservation.allowedKeycardCount)
      );
    }
  }
}
