import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'serialNumberPipe' })
export class SerialNumberPipe implements PipeTransform {
  transform(serialNumber: string | null): string {
    if (!!serialNumber) {
      const chunks = serialNumber.match(/.{1,4}/g);
      if (chunks) {
        return chunks.join('-');
      }
    }
    return '';
  }
}
