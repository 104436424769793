import { Component, Input, OnInit } from '@angular/core';
import { DoorAndCode } from 'app/models/doorAndCode';

@Component({
  selector: 'latch-doorcode-display',
  templateUrl: './doorcode-display.component.html'
})
export class DoorcodeDisplayComponent implements OnInit {
  @Input() doorsAndCodes: DoorAndCode[];
  @Input() doorcodeStartTime: Date;
  @Input() doorcodeEndTime: Date;
  @Input() hasTemporaryDoorcode: boolean;
  @Input() privateDoorPlaceholderName?: string;

  showPrivateDoorPlaceholder: boolean;

  constructor() {}

  ngOnInit(): void {
    if (this.privateDoorPlaceholderName) {
      this.showPrivateDoorPlaceholder = true;
    }
  }
}
