export class Reservation {
  allowedKeycardCount: number;
  usedKeycardCount: number;
  partnerId: string;
  isDoorcodeEnabled: boolean;

  constructor(allowedKeycardCount: number, usedKeycardCount: number, partnerId: string, isDoorcodeEnabled: boolean) {
    this.allowedKeycardCount = allowedKeycardCount;
    this.usedKeycardCount = usedKeycardCount;
    this.partnerId = partnerId;
    this.isDoorcodeEnabled = isDoorcodeEnabled;
  }

  getAvailableKeycardCount(): number {
    const availableKeycardCount = this.allowedKeycardCount - this.usedKeycardCount;
    if (availableKeycardCount < 0) {
      throw new Error('Used keycard count is greater than allowed keycard count');
    }
    return availableKeycardCount;
  }
}
