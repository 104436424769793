import { Component, Input } from '@angular/core';

@Component({
  selector: 'latch-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  @Input() isActive: boolean;

  constructor() {}
}
