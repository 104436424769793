export class Admittance {
  id: string;
  type: AdmittanceType;
  startTime: Date;
  endTime?: Date;
  doorDetails: Door[];
  isConnected: boolean;

  constructor(
    id: string,
    type: AdmittanceType,
    startTime: Date,
    doorDetails: Door[],
    isConnected: boolean,
    endTime?: Date
  ) {
    this.id = id;
    this.type = type;
    this.startTime = startTime;
    this.doorDetails = doorDetails;
    this.endTime = endTime;
    this.isConnected = isConnected;
  }

  isActive(): boolean {
    const now = new Date();
    return now >= this.startTime && (!this.endTime || now <= this.endTime);
  }

  isExpired(): boolean {
    if (!this.endTime) {
      return false;
    }
    const now = new Date();
    return now > this.endTime;
  }
}

export class Door {
  id: string;
  name: string;
  accessibility: LockAccessibility;

  constructor(id: string, name: string, accessibility: LockAccessibility) {
    this.id = id;
    this.name = name;
    this.accessibility = accessibility;
  }
}

export enum AdmittanceType {
  Reservation = 'RESERVATION'
}

export enum LockAccessibility {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
  Communal = 'COMMUNAL',
  Service = 'SERVICE'
}
