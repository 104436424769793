import { Component, Input } from '@angular/core';
import { Admittance } from 'app/models/admittance';

@Component({
  selector: 'latch-admittance-time',
  templateUrl: './admittance-time.component.html'
})
export class AdmittanceTimeComponent {
  @Input() admittance: Admittance;

  constructor() {}
}
