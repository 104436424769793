import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycardManualEntry } from 'app/components/template/content-keys';
import {
  AdmittanceService,
  AdmittanceServiceErrorMessages,
  AdmittanceServiceErrors
} from 'app/services/admittance/admittance.service';
import { ContentService } from 'app/services/content/content.service';
import { LinkService } from 'app/services/link/link.service';

import { KeycardBaseComponent } from '../keycard-input-base.component';

interface FormErrors {
  serialNumber?: string;
  serverMessage?: string;
}

@Component({
  selector: 'latch-keycard-serial-number-entry',
  templateUrl: './keycard-serial-number-entry.component.html'
})
export class KeycardSerialNumberEntryComponent extends KeycardBaseComponent {
  errors: FormErrors = {};
  serialNumber: string | null = null;
  KeycardManualEntry = KeycardManualEntry;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected admittanceService: AdmittanceService,
    protected contentService: ContentService,
    protected linkService: LinkService,
    protected router: Router,
    @Inject(DOCUMENT) protected document: Document
  ) {
    super(activatedRoute, admittanceService, contentService, linkService, router, document);
  }

  get canSubmit(): boolean {
    return !!this.serialNumber;
  }

  handleSubmit(): void {
    this.validateForm();
    if (!this.errors.serialNumber) {
      this.submit();
    }
  }

  updateSerialNumber(str: string): void {
    this.serialNumber = str.replace(/-/g, '');
  }

  private submit(): void {
    this.isLoading = true;
    if (!this.serialNumber) {
      throw new TypeError('Serial number cannot be null');
    }
    this.admittanceService.postReservationKeycard(this.token, this.serialNumber).subscribe(
      () => {
        this.isLoading = false;
        this.router.navigate(['/', this.token, 'keycards', this.serialNumber, 'confirmation'], {
          queryParamsHandling: 'preserve'
        });
      },
      error => {
        if (AdmittanceServiceErrors.INVALID_KEYCARD_SERIAL_NUMBER === error.message) {
          this.errors.serverMessage = AdmittanceServiceErrorMessages.get(
            AdmittanceServiceErrors.INVALID_KEYCARD_SERIAL_NUMBER
          );
        } else {
          this.errors.serverMessage = AdmittanceServiceErrorMessages.get(AdmittanceServiceErrors.INTERNAL_SERVER_ERROR);
        }
        this.serialNumber = null;
        this.isLoading = false;
      }
    );
  }

  private validateForm(): void {
    this.errors = {};
    const validationResult: string | null = this.validateSerialNumber(this.serialNumber);
    if (validationResult) {
      this.errors.serialNumber = validationResult;
    }
  }
}
