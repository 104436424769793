import { Pipe, PipeTransform } from '@angular/core';
/*
 * pipe to format the display of door codes in the same format as it appears in the email
 * Usage:
 *	value | doorcodeFormat
 * Example:
 * {{ 1234567 | doorcodeFormat }}
 * formats to : `123 45 67`
 */
@Pipe({ name: 'doorcodeFormat' })
export class DoorcodeFormatPipe implements PipeTransform {
  transform(code: string): string {
    return code.substr(0, 3) + ' ' + code.substr(3, 2) + ' ' + code.substr(5, 2);
  }
}
