<latch-loading [isActive]="isLoading" class="latch-page">
  <div class="card" *ngIf="!isLoading && !!admittance && !!reservation">
    <div class="content">
      <markdown
        class="keycard-activated-confirmation"
        [data]="getMarkdown(KeycardConfirmation.KEYCARD_ACTIVATED_CONFIRMATION)"
      ></markdown>
      <markdown
        *ngIf="hasRemainingKeycards"
        class="remaining-keycard-instructions"
        [data]="getMarkdown(KeycardConfirmation.REMAINING_KEYCARDS_INSTRUCTIONS)"
      ></markdown>
      <markdown
        *ngIf="hasNoRemainingKeycards"
        class="no-remaining-keycard-instructions"
        [data]="getMarkdown(KeycardConfirmation.NO_REMAINING_KEYCARDS_INSTRUCTIONS)"
      ></markdown>
    </div>
    <div class="footer">
      <div *ngIf="hasRemainingKeycards">
        <div class="button-wrapper">
          <a href="{{ token }}/keycards/qr-code" class="latch-button-outline latch-dark">
            Activate Another
          </a>
        </div>
        <div class="button-wrapper">
          <a href="{{ token }}" class="latch-button latch-dark">
            I'm Done
          </a>
        </div>
      </div>
      <div *ngIf="!hasRemainingKeycards">
        <div class="button-wrapper">
          <a href="{{ token }}" class="latch-button latch-dark">
            OK
          </a>
        </div>
      </div>
      <div class="latch-powered">
        <img class="latch-powered-logo-light" src="../../../../assets/images/works-with-latch-light.svg" />
        <img class="latch-powered-logo-dark" src="../../../../assets/images/works-with-latch-dark.svg" />
      </div>
    </div>
  </div>
</latch-loading>
