import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { ContentService } from './content.service';

@Injectable()
export class HTTPContentService extends ContentService {
  constructor(private httpClient: HttpClient) {
    super();
  }
  getContent(styleId: string): Observable<any> {
    const url = `${environment.s3BaseUrl}/${styleId}/link-content.json`;
    return this.httpClient.get(url);
  }
}
