import { Injectable } from '@angular/core';
import { Style } from 'app/models/style';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { APIService } from '../api/api.service';

import { LinkService } from './link.service';

@Injectable()
export class HTTPLinkService extends LinkService {
  getStyle(token: string): Observable<Style> {
    const endpoint = `/v1/links/${token}/style`;
    return this.apiService.request<Style>('get', endpoint).pipe(
      map(response => response.payload.message),
      catchError((error: Error) => APIService.handleError(error))
    );
  }
}
