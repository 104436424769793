import { Injectable } from '@angular/core';
import { Style } from 'app/models/style';
import { Observable } from 'rxjs';

import { APIService } from '../api/api.service';

@Injectable()
export abstract class LinkService {
  constructor(protected apiService: APIService) {}

  /**
   * Returns the style enum for the specified Latch Link
   */
  abstract getStyle(token: string): Observable<Style>;
}
