import { Injectable } from '@angular/core';
import { ApiResponse } from '@latch/latch-web';
import { AdmittanceDoorcode } from 'app/models/admittanceDoorcode';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { APIService } from '../api/api.service';

import { Admittance } from './../../models/admittance';
import { Reservation } from './../../models/reservation';
import { AdmittanceService, ExistingOrCreatedUser } from './admittance.service';

@Injectable()
export class HTTPAdmittanceService extends AdmittanceService {
  getAdmittance(token: string): Observable<Admittance> {
    return this.apiService.request<Admittance>('get', `/v2/admittances/${token}`).pipe(
      map(response => {
        const message = response.payload.message;

        /* The response received from the server actually isn’t an Admittance object - its start and end time are raw numbers of
         milliseconds. Rather than create a new type to define what the server actually returns, we just use Admittance, but use
         type casts to tell Typescript that start and end time are actually numbers. The double assertion via ‘unknown’ is
         required because the number and date types are unrelated:
         https://basarat.gitbooks.io/typescript/docs/types/type-assertion.html#double-assertion”
        */
        const startTime = new Date(((message.startTime as unknown) as number) * 1000);
        const endTime = message.endTime ? new Date(((message.endTime as unknown) as number) * 1000) : undefined;

        return new Admittance(message.id, message.type, startTime, message.doorDetails, message.isConnected, endTime);
      }),
      catchError((error: Error) => APIService.handleError(error))
    );
  }

  getAdmittanceDoorcodes(token: string): Observable<AdmittanceDoorcode[]> {
    return this.apiService.request<AdmittanceDoorcode[]>('post', `/v1/reservations/${token}/doorcodes`, '{}').pipe(
      map(response => {
        const admittanceDoorcodes = response.payload.message;
        for (const code of admittanceDoorcodes) {
          code.startTime = new Date(((code.startTime as unknown) as number) * 1000);
          code.endTime = new Date(((code.endTime as unknown) as number) * 1000);
        }

        return admittanceDoorcodes;
      }),
      catchError((error: Error) => APIService.handleError(error))
    );
  }

  updateAdmittanceUser(token: string, userId: string): Observable<void> {
    const requestBody: UpdateAdmittanceRequest = { userId };
    return this.apiService.request<Object>('patch', `/v2/admittances/${token}`, requestBody).pipe(
      map(response => {}),
      catchError((error: Error) => APIService.handleError(error))
    );
  }

  getReservation(token: string): Observable<Reservation> {
    const endpoint = `/v1/reservations/${token}`;
    return this.apiService.request<Reservation>('get', endpoint).pipe(
      map(response => {
        const message = response.payload.message;
        return new Reservation(
          message.allowedKeycardCount,
          message.usedKeycardCount,
          message.partnerId,
          message.isDoorcodeEnabled
        );
      }),
      catchError((error: Error) => APIService.handleError(error))
    );
  }

  postReservationKeycard(token: string, keycardSerialNumber: string): Observable<void> {
    const requestBody: PostReservationKeycardRequest = { keycardSerialNumber };
    const endpoint = `/v1/reservations/${token}/keycards`;
    return this.apiService.request<Object>('post', endpoint, requestBody).pipe(
      map(response => {}),
      catchError((error: Error) => APIService.handleError(error))
    );
  }

  createUser(firstName: string, lastName: string, email: string, token: string): Observable<ExistingOrCreatedUser> {
    const requestBody: CreateUserRequest = { firstName, lastName, email };
    const endpoint = `/v2/admittances/${token}/users`;
    return this.apiService.request<ExistingOrCreatedUserResponse>('post', endpoint, requestBody).pipe(
      map((r: ApiResponse<ExistingOrCreatedUserResponse>) => createdUserFromResponse(r.payload.message)),
      catchError((error: Error) => APIService.handleError(error))
    );
  }
}

//
// Server request and response definitions
//

class UpdateAdmittanceRequest {
  userId: string;
}

class PostReservationKeycardRequest {
  keycardSerialNumber: string;
}

class CreateUserRequest {
  firstName: string;
  lastName: string;
  email: string;
}

class ExistingOrCreatedUserResponse {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  signupDate: number;
  created: boolean;
}

//
// Helper functions to convert between server response types and app data models
//

function createdUserFromResponse(createdUserResponse: ExistingOrCreatedUserResponse): ExistingOrCreatedUser {
  return {
    uuid: createdUserResponse.uuid,
    firstName: createdUserResponse.firstName,
    lastName: createdUserResponse.lastName,
    email: createdUserResponse.email,
    signupDate: new Date(createdUserResponse.signupDate * 1000),
    created: createdUserResponse.created
  };
}
