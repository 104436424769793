import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { AdmittanceDoorcode } from '../../models/admittanceDoorcode';

import { Admittance } from './../../models/admittance';
import { Reservation } from './../../models/reservation';
import { User } from './../../models/user';
import { APIService } from './../api/api.service';

/**
 * ExistingOrCreatedUser is a user with an additional bit of information indicating whether it is a newly created user
 * (or an existing user).
 */
export class ExistingOrCreatedUser extends User {
  created: boolean;
}

export const enum AdmittanceServiceErrors {
  INVALID_KEYCARD_SERIAL_NUMBER = 'INVALID_KEYCARD_SERIAL_NUMBER',
  ADMITTANCE_EXPIRED = 'ADMITTANCE_EXPIRED',
  ADMITTANCE_CANCELLED = 'ADMITTANCE_CANCELLED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
}

export const AdmittanceServiceErrorMessages = new Map<AdmittanceServiceErrors, string>([
  [AdmittanceServiceErrors.INVALID_KEYCARD_SERIAL_NUMBER, 'Keycard serial number is either invalid or already in use.'],
  [AdmittanceServiceErrors.ADMITTANCE_EXPIRED, 'Admittance has expired.'],
  [AdmittanceServiceErrors.ADMITTANCE_CANCELLED, 'Admittance has been cancelled.'],
  [AdmittanceServiceErrors.INTERNAL_SERVER_ERROR, 'An unexpected server error occured.']
]);

/**
 * Service that retrieves admittance information and allows us to update the user associated with an admittance.
 */
@Injectable()
export abstract class AdmittanceService {
  constructor(protected apiService: APIService) {}

  /**
   * Returns the specified admittance.
   */
  abstract getAdmittance(token: string): Observable<Admittance>;

  /**
   * Returns door codes for the specified admittance.
   */
  abstract getAdmittanceDoorcodes(token: string): Observable<AdmittanceDoorcode[]>;

  /**
   * Updates the user who is granted access to the given admittance.
   */
  abstract updateAdmittanceUser(token: string, userId: string): Observable<void>;

  /**
   * Returns reservation for the specified admittance.
   */
  abstract getReservation(token: string): Observable<Reservation>;

  /**
   * Adds keycard with serial number from keycardSerialNumber to reservation associated with
   * the supplied token. Will return a response with INVALID_KEYCARD_SERIAL_NUMBER and status code 400
   * for invalid keycard serial numbers or keycards that have already been activated. Successful requests
   * will return 200 with availableKeycardCount indicating the number of remaining keycards.
   */
  abstract postReservationKeycard(token: string, keycardSerialNumber: string): Observable<void>;

  /**
   * Creates a new Latch user.
   *
   * Creates a user with the given name and email, using token for authentication.
   *
   * If a Latch user with the given email already exists, returns that existing user instead. In that case, the
   * ExistingOrCreatedUser will have created=false, and the first and last name of the user might not match what
   * was sent in the request. The returned user gives the actual name of the Latch user.
   */
  abstract createUser(
    firstName: string,
    lastName: string,
    email: string,
    token: string
  ): Observable<ExistingOrCreatedUser>;
}
