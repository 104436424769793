import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';

import { environment } from './../environments/environment';
import { AdmittanceInfoComponent } from './components/admittance/admittance-info.component';
import { AdmittanceTimeComponent } from './components/admittance/template-components/admittance-time/admittance-time.component';
import { DoorcodeDisplayComponent } from './components/admittance/template-components/doorcode-display/doorcode-display.component';
import { AppComponent } from './components/app.component';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { KeycardConfirmationComponent } from './components/keycard/keycard-confirmation/keycard-confirmation.component';
import { KeycardLandingPageComponent } from './components/keycard/keycard-landing-page/keycard-landing-page.component';
import { KeycardQRScanningComponent } from './components/keycard/keycard-qr-scanning/keycard-qr-scanning.component';
import { KeycardSerialNumberEntryComponent } from './components/keycard/keycard-serial-number-entry/keycard-serial-number-entry.component';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { SpinnerComponent } from './components/shared/loading/spinner.component';
import { RadialTimerComponent } from './components/shared/radial-timer/radial-timer.component';
import { MarkdownOptionsFactory } from './components/template/markdown-options-factory';
import { DoorcodeFormatPipe } from './pipes/doorcode-format.pipe';
import { SerialNumberPipe } from './pipes/serial-number.pipe';
import { AdmittanceService } from './services/admittance/admittance.service';
import { HTTPAdmittanceService } from './services/admittance/http-admittance.service';
import { MockAdmittanceService } from './services/admittance/mock-admittance.service';
import { APIService } from './services/api/api.service';
import { ContentService } from './services/content/content.service';
import { HTTPContentService } from './services/content/http-content.service';
import { LocalContentService } from './services/content/local-content.service';
import { HTTPLinkService } from './services/link/http-link.service';
import { LinkService } from './services/link/link.service';
import { MockLinkService } from './services/link/mock-link.service';

@NgModule({
  declarations: [
    AppComponent,
    AdmittanceInfoComponent,
    CreateAccountComponent,
    KeycardConfirmationComponent,
    KeycardLandingPageComponent,
    KeycardQRScanningComponent,
    KeycardSerialNumberEntryComponent,
    LoadingComponent,
    RadialTimerComponent,
    SpinnerComponent,
    DoorcodeFormatPipe,
    SerialNumberPipe,
    AdmittanceTimeComponent,
    DoorcodeDisplayComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useFactory: MarkdownOptionsFactory
      }
    }),
    RouterModule.forRoot([
      {
        path: ':token',
        component: AdmittanceInfoComponent
      },
      {
        path: ':token/keycards',
        component: KeycardLandingPageComponent
      },
      {
        path: ':token/keycards/qr-code',
        component: KeycardQRScanningComponent
      },
      {
        path: ':token/keycards/serial-number',
        component: KeycardSerialNumberEntryComponent
      },
      {
        path: ':token/keycards/:serialNumber/confirmation',
        component: KeycardConfirmationComponent
      }
      /*,
      { path: '403', component: ErrorPageComponent },
      { path: '404', component: ErrorPageComponent },
      { path: '**', redirectTo: '/404' }*/
    ]),
    ZXingScannerModule
  ],
  providers: [
    { provide: AdmittanceService, useClass: environment.useMocks ? MockAdmittanceService : HTTPAdmittanceService },
    { provide: LinkService, useClass: environment.useMocks ? MockLinkService : HTTPLinkService },
    {
      provide: ContentService,
      useClass: environment.useLocalAssets || environment.useMocks ? LocalContentService : HTTPContentService
    },
    APIService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
