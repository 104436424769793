import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@latch/latch-web';
import * as _ from 'lodash';
import * as Raven from 'raven-js';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from './../../../environments/environment';

@Injectable()
export class APIService {
  static getError(response: HttpResponse<any> | HttpErrorResponse): any {
    if (response instanceof HttpErrorResponse) {
      if ('payload' in response.error) {
        if ('message' in response.error.payload) {
          return response.error.payload.message;
        }
      }
    }

    return null;
  }

  static handleError(error: Error) {
    if (error instanceof HttpErrorResponse && error.status < 500) {
      const message = APIService.getError(error).error;
      return throwError(new Error(message));
    } else {
      return throwError(error);
    }
  }

  constructor(private http: HttpClient) {}

  request<T>(
    method: string,
    endpoint: string,
    data?: any,
    headers?: HttpHeaders | { [name: string]: string | string[] },
    search?: HttpParams | { [param: string]: string | string[] }
  ): Observable<ApiResponse<T>> {
    const url = environment.apiBaseUrl + endpoint;
    const body = data ? JSON.stringify(data) : null;
    const httpHeaders = new HttpHeaders(
      Object.assign(
        {
          'Content-Type': 'application/json'
        },
        headers
      )
    );
    const httpParams = search instanceof HttpParams ? search : new HttpParams({ fromObject: search });

    return this.http
      .request(method, url, {
        headers: httpHeaders,
        params: httpParams,
        body,
        withCredentials: true
      })
      .pipe(
        tap((response: ApiResponse<T>) => {
          const bodyCleaned = _.cloneDeep(body);
          // Capture data about the response received from this endpoint so that if there's an error that's not an
          // actual HTTP error (an unexpected result, or an error later in the workflow) we can see exactly what
          // the server sent us.
          Raven.captureBreadcrumb({
            message: 'endpoint response',
            data: {
              endpoint,
              response: _.truncate(JSON.stringify(response), {
                length: 4096
              }),
              request: bodyCleaned,
              search: search ? search.toString() : null
            }
          });
        }),
        catchError((error: HttpErrorResponse) => throwError(error))
      );
  }
}
