export class AdmittanceDoorcode {
  doorcode: string;
  lockName: string;
  lockId: string;
  startTime: Date;
  endTime: Date;
  keyMembershipId: string;
  passcodeType: PasscodeType;
}

export enum PasscodeType {
  DAILY = 'DAILY',
  PERMANENT = 'PERMANENT',
  DAILY_SINGLE_USE = 'DAILY_SINGLE_USE'
}
