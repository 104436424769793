import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

export function MarkdownOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.paragraph = function(text) {
    // prevent images from being wrapped in <p>
    if (text.startsWith('<img')) {
      return text;
    } else {
      return `<p>${text}</p>`;
    }
  };
  return {
    renderer: renderer
  };
}
