<div class="latch-divider"></div>
<div class="cell-admission">
  <div>Starting</div>
  <div class="cell-admission-time-content">
    <div class="icon-calendar"></div>
    <div class="text-admission-date">{{ admittance.startTime | date: 'MMM d, y' }}</div>
    <div class="text-admission-time">{{ admittance.startTime | date: 'h:mm a' }}</div>
  </div>
</div>
<div *ngIf="!!admittance.endTime">
  <div class="latch-divider"></div>
  <div class="cell-admission">
    <div>Ending</div>
    <div class="cell-admission-time-content">
      <div class="icon-calendar"></div>
      <div class="text-admission-date">{{ admittance.endTime | date: 'MMM d, y' }}</div>
      <div class="text-admission-time">{{ admittance.endTime | date: 'h:mm a' }}</div>
    </div>
  </div>
</div>
<div class="latch-divider"></div>
<br />
