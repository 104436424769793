import { LATCH_DEFAULT_STYLE_ID, Style } from 'app/models/style';
import { environment } from 'environments/environment';
import _ from 'lodash';

import LATCH_DEFAULT_CONTENT from './../../../assets/content/latch-content.json';
import { ContentStringKey, MarkdownSectionKey, TemplateVariable } from './content-keys';

/**
 * ContentProvider will parse the content files and "resolve" pieces of content by looking up subpaths
 * (paths to images and resources) as well as injecting template variables.
 */
export class ContentProvider {
  private static LOCAL_IMAGE_DIRECTORY = 'assets/images';

  static getInstance(style: Style, content: any) {
    const imageDirectory = environment.useLocalAssets
      ? ContentProvider.LOCAL_IMAGE_DIRECTORY
      : `${environment.s3BaseUrl}/${style.id}/images`;
    return new ContentProvider(content, imageDirectory);
  }

  static getNestedMarkdown(contentObject: object, markdownSectionKey: MarkdownSectionKey): string {
    const nestedMarkdown = markdownSectionKey.split('.').reduce((acc, key) => acc && acc[key], contentObject);
    if (nestedMarkdown === undefined) {
      throw new Error(`No value set for markdown section key: ${markdownSectionKey}`);
    }
    return nestedMarkdown;
  }

  private content: object;
  private defaultContent: object;
  private templateVariables: object;
  private imageDirectory: string;
  private defaultImageDirectory = `${environment.s3BaseUrl}/${LATCH_DEFAULT_STYLE_ID}/images`;

  constructor(content: object, imageDirectory: string) {
    this.content = content;
    this.imageDirectory = imageDirectory;
    this.defaultContent = LATCH_DEFAULT_CONTENT;
    this.templateVariables = {};
  }

  setTemplateVariable(key: TemplateVariable, value: string): void {
    this.templateVariables[key] = value;
  }

  // Returns markdown that has been formatted with template variables and appropriate image urls.
  // This method should only be called for sections that contain markdown, intended to be used as data inputs for markdown components.
  // For content strings, use the getContentString() method.
  getFormattedMarkdown(markdownSectionKey: MarkdownSectionKey): string | null {
    let useDefaultContent = false;
    let contentMarkdown;
    try {
      contentMarkdown = ContentProvider.getNestedMarkdown(this.content, markdownSectionKey);
    } catch (e) {
      contentMarkdown = ContentProvider.getNestedMarkdown(this.defaultContent, markdownSectionKey);
      useDefaultContent = true;
    }

    const imageBaseUrl = useDefaultContent ? this.defaultImageDirectory : this.imageDirectory;
    const compilerVariables = _.merge(this.templateVariables, { IMAGE_DIRECTORY: imageBaseUrl });
    // TODO: enforce stricter rules for markdown content
    const compile = _.template(contentMarkdown, { interpolate: /{{([\s\S]+?)}}/g });
    try {
      return compile(compilerVariables);
    } catch (error) {
      // Catch reference errors for variables used in templates that are undefined in compilerVariables
      // Opting to log errors but continue to render valid sections of template
      console.warn('Template references undefined variable:');
      console.warn(error);
    }
    return null;
  }

  // Will return a content string or throw an error if the content string has not been set.
  // Content strings are not intended to be to be used as data inputs for markdown components, typically they are used as parameters for other components.
  // For markdown sections, use the getFormattedMarkdown() method.
  getContentString(contentStringKey: ContentStringKey): string {
    let nestedValue = contentStringKey.split('.').reduce((acc, key) => acc && acc[key], this.content);
    if (nestedValue === undefined) {
      nestedValue = contentStringKey.split('.').reduce((acc, key) => acc && acc[key], this.defaultContent);
    }

    if (nestedValue === undefined) {
      throw new Error(`Content string: ${contentStringKey} has not been set.`);
    }
    return nestedValue;
  }
}
