import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * The Content Service is responsible for retrieving content.json files, either from the local
 * file system or from S3.
 */
@Injectable()
export abstract class ContentService {
  /**
   * @param styleId id of style associated with Latch Link
   * Returns json object containing content configuration
   */
  abstract getContent(styleId: string): Observable<any>;
}
