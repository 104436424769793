import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'latch-radial-timer',
  templateUrl: './radial-timer.component.html',
  styleUrls: ['./radial-timer.component.scss']
})
/** Pie chart style timer that shows progress from timeStart to timeEnd */
export class RadialTimerComponent implements OnInit {
  @Input() timeStart: Date;
  @Input() timeEnd: Date;

  private startPercentage: number;
  private secondsLeft: number;

  ngOnInit() {
    const durationMs = this.timeEnd.getTime() - this.timeStart.getTime();
    const elapsedMs = new Date().getTime() - this.timeStart.getTime();

    this.startPercentage = Math.round(100 * (elapsedMs / durationMs));
    this.secondsLeft = Math.round((durationMs - elapsedMs) / 1000);
  }

  getAnimationStartPoint(): string {
    return `${this.startPercentage} 100`;
  }

  getAnimationDuration(): string {
    return `${this.secondsLeft}s`;
  }
}
