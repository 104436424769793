import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CssLoader } from 'app/components/shared/css-util/css-loader';
import { KeycardOnboardingInfo, MarkdownSectionKey, TemplateVariable } from 'app/components/template/content-keys';
import { ContentProvider } from 'app/components/template/content-provider';
import { Admittance, AdmittanceType } from 'app/models/admittance';
import { Reservation } from 'app/models/reservation';
import { Style } from 'app/models/style';
import { AdmittanceService, AdmittanceServiceErrors } from 'app/services/admittance/admittance.service';
import { ContentService } from 'app/services/content/content.service';
import { LinkService } from 'app/services/link/link.service';
import { Observable, throwError } from 'rxjs';
import { concatMap, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'latch-keycard-landing-page',
  templateUrl: './keycard-landing-page.component.html'
})
export class KeycardLandingPageComponent implements OnInit {
  isLoading: boolean;
  token: string;
  reservation: Reservation | null = null;
  KeycardOnboardingInfo = KeycardOnboardingInfo;

  private style: Style;
  private contentProvider: ContentProvider;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private admittanceService: AdmittanceService,
    private contentService: ContentService,
    private linkService: LinkService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.handlePageLoad();
  }

  get hasRemainingKeycards(): boolean {
    return !!this.reservation && this.reservation.getAvailableKeycardCount() > 0;
  }

  get hasExistingKeycards(): boolean {
    return !!this.reservation && this.reservation.getAvailableKeycardCount() !== this.reservation.allowedKeycardCount;
  }

  getMarkdown(markdownSectionKey: MarkdownSectionKey): string | null {
    return this.contentProvider.getFormattedMarkdown(markdownSectionKey);
  }

  private handlePageLoad(): void {
    const token$ = this.activatedRoute.params.pipe(
      map((params: Params) => params.token),
      distinctUntilChanged(),
      take(1)
    );

    token$
      .pipe(
        switchMap(token => {
          this.token = token;
          return this.linkService.getStyle(token);
        }),
        concatMap(style => {
          this.style = style;
          CssLoader.loadStyle(this.style, this.document);
          return this.contentService.getContent(this.style.id);
        }),
        concatMap(content => {
          this.contentProvider = ContentProvider.getInstance(this.style, content);
          return this.admittanceService.getAdmittance(this.token);
        }),
        concatMap(admittance => this.getReservationForAdmittance(admittance, this.token))
      )
      .subscribe(
        reservation => {
          this.reservation = reservation;
          this.contentProvider.setTemplateVariable(
            TemplateVariable.AVAILABLE_KEYCARD_COUNT,
            String(reservation.getAvailableKeycardCount())
          );
          this.contentProvider.setTemplateVariable(
            TemplateVariable.ALLOWED_KEYCARD_COUNT,
            String(reservation.allowedKeycardCount)
          );
          this.isLoading = false;
        },
        error => {
          this.reservation = null;
          console.error(error);
          if (
            error.message === AdmittanceServiceErrors.ADMITTANCE_EXPIRED ||
            error.message === AdmittanceServiceErrors.ADMITTANCE_CANCELLED
          ) {
            this.router.navigate(['/', this.token]);
          }
          if (this.style === undefined) {
            CssLoader.loadDefaultStyle(this.document);
          }
          this.isLoading = false;
        }
      );
  }

  // We include this check here since we anticipate that some users will start
  // from this page and not from the admittance-info page, where this check is initially performed.
  private getReservationForAdmittance(admittance: Admittance, token: string): Observable<Reservation> {
    if (admittance.type === AdmittanceType.Reservation) {
      return this.admittanceService.getReservation(token);
    } else {
      return throwError('Admittance is not a Reservation');
    }
  }
}
