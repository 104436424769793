import { LATCH_DEFAULT_STYLE_ID, PYNWHEEL_STYLE_ID, Style, THE_GUILD_STYLE_ID } from 'app/models/style';
import { environment } from 'environments/environment';

export class CssLoader {
  static loadStylesheet(path: string, document: Document): void {
    const headEl = document.getElementsByTagName('head')[0];
    const newLinkEl = document.createElement('link');
    newLinkEl.rel = 'stylesheet';
    newLinkEl.href = path;
    headEl.appendChild(newLinkEl);
  }

  // Loads style from S3
  static loadStyle(style: Style, document: Document): void {
    const filepath = environment.useLocalAssets
      ? this.getLocalCss(style)
      : `${environment.s3BaseUrl}/${style.id}/partner-style.css`;
    CssLoader.loadStylesheet(filepath, document);
  }

  static loadDefaultStyle(document: Document): void {
    const filepath = environment.useLocalAssets
      ? 'latch-style.css'
      : `${environment.s3BaseUrl}/${LATCH_DEFAULT_STYLE_ID}/partner-style.css`;
    CssLoader.loadStylesheet(filepath, document);
  }

  private static getLocalCss(style: Style): string {
    switch (style.id) {
      case LATCH_DEFAULT_STYLE_ID:
        return 'latch-style.css';
      case THE_GUILD_STYLE_ID:
        return 'the-guild-style.css';
      case PYNWHEEL_STYLE_ID:
        return 'pynwheel-style.css';
      default:
        throw new TypeError(`CSS mapping for style ${style.id} has not been configured`);
    }
  }
}
