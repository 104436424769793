<latch-loading [isActive]="isLoading" class="latch-page">
  <div class="card" *ngIf="!isLoading">
    <div class="content">
      <markdown class="scanning-instructions" [data]="getMarkdown(KeycardScanning.SCANNING_INSTRUCTIONS)"></markdown>
      <zxing-scanner
        id="qr-scanner"
        [formats]="allowedFormats"
        (camerasNotFound)="handleNoCameras()"
        (scanSuccess)="handleScanSuccess($event)"
        (scanError)="handleScanError($event)"
      ></zxing-scanner>
      <div class="error-message" [hidden]="!errorMessage">{{ errorMessage }}</div>
    </div>
    <div class="footer">
      <p>
        Having trouble? You can manually enter your Keycard serial numbers
        <a href="{{ token }}/keycards/serial-number">here</a>.
      </p>
      <div class="latch-powered">
        <img class="latch-powered-logo-light" src="../../../../assets/images/works-with-latch-light.svg" />
        <img class="latch-powered-logo-dark" src="../../../../assets/images/works-with-latch-dark.svg" />
      </div>
    </div>
  </div>
</latch-loading>
