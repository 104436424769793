export class Style {
  id: string;

  constructor(id: string) {
    this.id = id;
  }
}

// These two hardcoded styles exist for logic related to default content and the local content service.
// The system should not have to be aware of any other styles.

export const LATCH_DEFAULT_STYLE_ID = '2fd95a4c-4f73-47c1-9401-776ec20223d3';
export const THE_GUILD_STYLE_ID = '612428fe-afb0-4392-89c9-7c8385705045';
export const PYNWHEEL_STYLE_ID = '2b22c4b5-2f51-43f8-925f-db5062c27c83';
