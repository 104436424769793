<div class="latch-divider"></div>
<!-- Private Door Placeholder -->
<div *ngIf="showPrivateDoorPlaceholder" class="private-door-placeholder">
  <div class="cell-door-code">
    <div class="icon-door-background">
      <div class="icon-door door-private"></div>
    </div>
    <div class="cell-door-code-inner-container">
      <div class="text-door-name">{{ privateDoorPlaceholderName }}</div>
      <div class="text-door-code">xxx xx xx</div>
    </div>
  </div>
  <div class="latch-divider"></div>
</div>
<div class="doorcodes-container">
  <div class="doorcode-container" *ngFor="let doorAndCode of doorsAndCodes">
    <div class="cell-door-code">
      <div class="icon-door-background">
        <div
          class="icon-door"
          [ngClass]="doorAndCode.door.accessibility === 'PRIVATE' ? 'door-private' : 'door-public'"
        ></div>
      </div>
      <div class="cell-door-code-inner-container">
        <div class="text-door-name">{{ doorAndCode.door.name }}</div>
        <div *ngIf="!!doorAndCode.doorcode" class="text-door-code">
          {{ doorAndCode.doorcode | doorcodeFormat }}
        </div>
      </div>
      <latch-radial-timer
        *ngIf="!!doorAndCode.doorcode && hasTemporaryDoorcode"
        class="icon-door-code-time"
        [timeStart]="doorcodeStartTime"
        [timeEnd]="doorcodeEndTime"
      >
      </latch-radial-timer>
    </div>
    <div class="latch-divider"></div>
  </div>
</div>
