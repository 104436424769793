<latch-loading [isActive]="isLoading" class="latch-page">
  <div class="card" *ngIf="!isLoading">
    <div class="content">
      <markdown
        class="manual-entry-instructions"
        [data]="getMarkdown(KeycardManualEntry.MANUAL_ENTRY_INSTRUCTIONS)"
      ></markdown>
      <div class="latch-form-field serial-number-entry-field">
        <input
          class="latch-form-control"
          placeholder="Serial number"
          [ngModel]="serialNumber | serialNumberPipe"
          (ngModelChange)="updateSerialNumber($event)"
          type="text"
          maxlength="14"
        />
        <div class="latch-error" *ngIf="!!errors.serialNumber">{{ errors.serialNumber }}</div>
        <div class="latch-error" *ngIf="!!errors.serverMessage">{{ errors.serverMessage }}</div>
      </div>
    </div>
    <div class="footer">
      <p>
        Don't want to type? You can scan your Keycard serial numbers
        <a href="{{ token }}/keycards/qr-code">here</a>.
      </p>
      <br />
      <div class="button-wrapper">
        <button [disabled]="!canSubmit" class="latch-button latch-dark" (click)="handleSubmit()">
          Activate
        </button>
      </div>
      <div class="latch-powered">
        <img class="latch-powered-logo-light" src="../../../../assets/images/works-with-latch-light.svg" />
        <img class="latch-powered-logo-dark" src="../../../../assets/images/works-with-latch-dark.svg" />
      </div>
    </div>
  </div>
</latch-loading>
